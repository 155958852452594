import './App.css';

function App() {
  return (
    <div className="App" style={{backgroundImage: "url(/background.png)"}}>
      <div className="App-header">
        blush dunes make reservoirs
      </div>
    </div>
  );
}


export default App;
